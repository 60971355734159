@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.button {
  font-size: 16px;
  color: $text;
  font-weight: 500;
  width: 100%;
  max-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  text-align: center;
  height: 58px;
  opacity: 1;
  border-radius: 12px;
  padding: 0 18px;
  transition:
          background-color 0.225s ease,
          color 0.225s ease,
          opacity 0.225s ease;

  &:disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  @include focus-visible;

  &--main {
    background-color: $button-color;

    @include hover-supported {
      background-color: $button-color-hover;
    }

    &:active {
      background-color: $button-color-hover;
    }
  }

  &--white {
    background-color: $white;
    border: 1px solid $text;

    @include hover-supported {
      background-color: $text;
      color: $white;
    }
  }
}
