.table {
  margin: 20px 0;

  @media (max-width: 1330px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    width: 100%;

    td,
    th {
      border: 1px solid #727A85;
      text-align: left;
      vertical-align: top;
    }
  }

  th {
    text-transform: uppercase;
    color: #111D21;
    font-size: 16px;
    line-height: 22px;

    &:first-child {
      text-transform: none;
    }
  }

  td,
  th {
    padding: 15px;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    color: #727A85;
    vertical-align: top;

    p {
      margin-bottom: 0;
    }
  }

  &--principles {
    table {
      min-width: 1280px;

      td,
      th {
        &:nth-child(1) {
          width: 3.9%;
        }

        &:nth-child(2) {
          width: 21.5%;
        }

        &:nth-child(3) {
          width: 24.86%;
        }

        &:nth-child(4) {
          width: 24.86%;
        }

        &:nth-child(5) {
          width: 24.86%;
        }
      }
    }
  }

  &--rights {
    table {
      min-width: 1280px;
      table-layout: fixed;

      td,
      th {
        @include for-desktop-up {
        }
      }
    }
  }
}
