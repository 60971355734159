.hamburger {
  font-size: 0;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;

  .hamburger-box {
    position: relative;
    display: inline-flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    width: 40px;
    height: 40px;
  }

  .hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -1px;

    &:after,
    &:before {
      display: block;
      content: "";
    }

    &:after {
      bottom: -6.5px;
    }

    &:before {
      top: -6.5px;
    }
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    position: absolute;
    width: 22px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 8px;
    background-color: #9391A6;
  }

  &.is-active {
    .hamburger-inner {
      background-color: #9391A6 !important;

      &:before,
      &:after {
        background-color: #9391A6;
      }
    }
  }

  &--spin {
    .hamburger-inner {
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition-duration: 0.22s;

      &:before {
        transition:
          top 0.1s ease-in 0.25s,
          opacity 0.1s ease-in;
      }

      &:after {
        transition:
          bottom 0.1s ease-in 0.25s,
          transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(225deg);

        &:before {
          top: 0;
          transition:
            top 0.1s ease-out,
            opacity 0.1s ease-out 0.12s;
          opacity: 0;
        }

        &:after {
          bottom: 0;
          transition:
            bottom 0.1s ease-out,
            transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
