@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.security-cta {
  position: relative;
  overflow: hidden;
  background: $bg-grey;

  & &__wrapper {
    position: relative;
    padding-top: 0;
    padding-bottom: 120px;
    z-index: 1;

    @include for-desktop-up {
      padding-top: 141px;
      padding-bottom: 141px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    padding: 0 0 60px 0;

    @include for-desktop-up {
      flex-direction: row;
      padding: 60px 0 20px;
    }
  }

  &__text-section {
    max-width: 692px;

    @include for-desktop-down {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 1;
    }
  }

  & &__title {
    color: $white;
    text-align: left;
    max-width: 556px;
    width: 100%;
  }

  & &__subtitle {
    text-align: left;
    margin-top: 20px;
    max-width: 556px;

    @include for-desktop-up {
      margin-top: 26px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    margin: 50px 0 64px;

    @media (min-width: 1100px) {
      grid-template-columns: 1fr 1fr;
      gap: 56px 40px;
    }

    @include for-desktop-up {
      margin: 64px 0 96px;
    }

    @include for-macbook-13-inch {
      margin-bottom: 50px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 24px;

    h3 {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__button {
    @include for-desktop-down {
      max-width: 343px;
    }
  }

  &__image-wrapper {
    flex: 1 0 450px;

    @include for-desktop-down {
      width: calc(100% + 32px);
      text-align: center;
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 140px;
    }

    @media (max-width: 530px) {
      position: relative;
    }

    img {
      position: relative;
      width: 100%;
      height: auto;
      max-width: 262px;
      z-index: 1;

      @include for-desktop-up {
        max-width: 494px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__decor {
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 0;

    @media (max-width: 530px) {
      bottom: -50px;
    }
  }
}
