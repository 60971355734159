@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.mobile-scan {
  position: relative;
  overflow: hidden;
  background: $bg;

  @include for-desktop-down {
    background: $bg-mobile;
  }

  & > * {
    box-sizing: border-box;
  }

  & &__wrapper {
    padding-top: 100px;
    padding-bottom: 60px;

    @include for-desktop-up {
      padding-top: 0;
      padding-bottom: 96px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;

    @include for-desktop-up {
      flex-direction: row;
      gap: 120px;
    }

    @include for-tablet {
      gap: 40px;
    }
  }

  &__text-slider {
    flex: 1;
    max-width: calc(100vw - 16px * 2);

    .navigation {
      @include for-desktop-down {
        display: none;
      }
    }
  }

  &__text-slide {
    max-width: 556px;

    .top {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      color: #66EAA2;
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;

      @include for-desktop-down {
        font-size: 0;
        margin-left: -16px;
      }
    }

    .label {
      min-width: 122px;
      box-sizing: border-box;
      border-radius: 28px;
      border: 1px solid $text;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      color: $text;
      text-transform: none;
    }

    h3 {
      color: $text;
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
      margin-top: 12px;

      @include for-desktop-up {
        font-size: 60px;
        line-height: 66px;
      }
    }

    p {
      margin-top: 20px;
      color: #727A85;

      @include for-desktop-up {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 30px;
      }
    }
  }

  &__picture-slider {
    flex: 1;
  }

  &__picture-slide {
    max-width: 260px;

    @include for-desktop-up {
      max-width: 480px;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 17px;
      overflow: hidden;

      @include for-desktop-up {
        border-radius: 32px;
      }
    }
  }

  .navigation-btn svg {
    color: #FFF;
    transition: 0.3s ease;

    &:hover {
      color: $white;
    }
  }
}
