.swiper-fade {
  .swiper-slide {
    opacity: 0 !important;

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }
}

.navigation {
  display: flex;
  gap: 20px;
  margin-top: 30px;

  .navigation-btn {
    width: 80px;
    height: 80px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include for-desktop-up {
      cursor: pointer;
    }
  }
}

.swiper-pagination {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;

  @include for-desktop-up {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #eee5d2;
    opacity: 1;
    margin: 0 !important;
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: $green;
    opacity: 1;
  }
}




