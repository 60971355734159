@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.top-features {
  background: $bg;

  @include for-desktop-down {
    background: $bg-mobile;
  }

  & > * {
    box-sizing: border-box;
  }

  & &__wrapper {
    padding-bottom: 60px;
    max-width: 1400px;

    @include for-desktop-up {
      padding-top: 110px;
      padding-bottom: 100px;
    }
  }

  &__slider {
    max-width: calc(100vw - 16px * 2);
  }

  &__slide {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 48px;

    @media (min-width: 1200px) {
      flex-direction: row;
      gap: 40px;
    }

    .video-wrap {
      flex: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 1200px) and (max-width: 1340px) {
        flex: 0 0 640px;
      }
    }

    .video-block {
      height: 475px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1200px) {
        height: 410px;
      }

      @media (max-width: 550px) {
        height: 340px;
      }

      @include for-mobile-down {
        height: 290px;
      }
    }

    .video {
      flex: 100%;
      max-width: 720px;
      min-height: 475px;

      @media (max-width: 1200px) {
        max-width: 500px;
        flex: 100%;
      }

      @media (max-width: 550px) {
        max-width: 360px;
      }

      @include for-mobile-down {
        max-width: 320px;
      }
    }

    .image-preview {
      max-width: 340px;

      @media (max-width: 1200px) {
        max-width: 280px;
      }

      @include for-desktop-down {
        max-width: 320px;
      }

      @media (max-width: 550px) {
        max-width: 240px;
      }

      @include for-mobile-down {
        max-width: 210px;
      }
    }

    .text-wrap {
      flex: 100%;
      max-width: 615px;

      @media (min-width: 1200px) {
        max-width: 556px;
      }
    }

    .top {
      color: #66EAA2;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;

      @include for-desktop-up {
        font-size: 24px;
      }
    }

    h3 {
      color: $text;
      font-size: 44px;
      font-weight: 700;
      line-height: 50px;
      margin-top: 12px;

      @include for-desktop-up {
        font-size: 60px;
        line-height: 66px;
      }
    }

    p {
      margin-top: 20px;
      color: #727A85;

      @include for-desktop-up {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 30px;
      }
    }

    .buttons_wrap {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 12px;
    }

    button {
      min-width: 169px;
      height: 43px;
      border-radius: 28px;
      border: 1px solid #66EAA2;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease;
      z-index: 1;
      box-sizing: border-box;

      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #66EAA2;
      text-transform: none;

      @include for-mobile-down {
        min-width: auto;
        height: 32px;
      }

      @include for-desktop-up {
        font-size: 16px;
      }

      &:hover {
        background: #E0FFEE;
      }

      &.is-active {
        background: $green;
        color: $text;
      }
    }
  }
}
