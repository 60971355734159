// common typography settings
$base-font-size: 16px;

// header height settings
$header-height: 60px;
$header-height-mobile: 60px;

// colors settings
$white: #F6F6F6;
$text: #111D21;
$green: #67EAA2;
$button-color: #67EAA2;
$button-color-hover: #54df92;
$bg: #F0FFF7;
$bg-mobile: #F0FFF7;
$bg-grey: #21363F;
$bg-dark: #2A2A2A;

$bg-grey1: #303030;
$bg-grey2: #525252;

$path-to-static-fonts: '../fonts/';
