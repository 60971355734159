.contents {
  &__list {
    margin-top: 12px;
  }

  &__item {
    a {
      color: #727A85 !important;
      display: inline-block;
      text-decoration: none;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;

      &:hover {
        color: $button-color-hover;
      }
    }

    &:before {
      top: 10px !important;
    }
  }

  &__link {
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}

.policy__contents {
  margin-top: 30px;
  margin-bottom: 30px;

  .headline {
    margin-bottom: 12px;
  }
}

ul.contents {
  margin-bottom: 32px !important;

  li {
    margin: 0 0 0 20px;
  }
}
