@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.nav {
  position: fixed;
  top: $header-height-mobile;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow: auto;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.225s ease;
  padding: 25px 16px;

  &.is-opened {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
    background-color: #fafaff;
  }

  @include for-desktop-up {
    flex: 100%;
    position: static;
    padding: 0;
    width: auto;
    opacity: 1;
    pointer-events: auto;
    transition: none;
    overflow: visible;
    box-shadow: none;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;

    &.is-hide-desktop {
      display: none;
    }
  }

  &-items {
    @include for-desktop-up {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }

    & > ul {
      display: flex;

      @include for-desktop-down {
        flex-direction: column;
        gap: 16px;
        max-width: calc(630px - 32px);
        margin: 0 auto 50px;

        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  &-item {
    position: relative;

    &.active {
      a {
        @include for-desktop-down {
          color: $button-color;
        }
      }
    }

    @include hover-supported {
      .nav-link {
        color: $green;
      }
    }

    &.is-active {
      .nav-link {
        color: $green;
      }
    }
  }

  &-link {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    color: $text;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    @include for-desktop-up {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      height: $header-height-mobile;
      transition: color 0.225s ease;
    }

    @include for-tablet {
      padding: 0 20px;
    }

    @include focus-visible;
  }

  &-text {
    position: relative;
    white-space: nowrap;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      height: 1px;
      background-color: transparent;
      transition: background-color 0.225s ease;
    }
  }

  &__button {
    max-width: calc(630px - 32px);
    margin: 16px auto 0;

    @include for-desktop-up {
      display: none;
    }
  }
}
