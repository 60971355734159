@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.page-404 {
  background: #fff;
  &__content {
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    @include for-desktop-up {
      padding: 110px 0;
    }
  }

  &__image {
    max-width: 300px;
  }
}
