@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.activate {
  background: $bg;
  overflow: hidden;

  @include for-desktop-down {
    background: $bg-mobile;
  }

  & &__wrapper {
    padding-top: 100px;

    @include for-desktop-down {
      padding-top: 40px;
      padding-bottom: 164px;
    }
  }

  &__container {
    background: $bg-grey;
    padding: 64px 40px 235px;
    border-radius: 32px;
    position: relative;

    @include for-desktop-up {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }

  & &__title {
    color: $white;
  }

  &__items {
    margin: 48px auto;
    display: flex;
    flex-direction: column;
    gap: 64px;
    max-width: 720px;

    @include for-desktop-up {
      margin-top: 64px;
      margin-bottom: 64px;
      padding-top: 32px;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
    }
  }

  &__item {
    flex: 0 0 168px;

    img {
      display: block;
      margin: 0 auto;
    }

    h3 {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 16px;
      text-align: center;

      @include for-desktop-up {
        font-size: 18px;
        line-height: 24px;
        margin-top: 32px;
        text-align: left;
      }
    }
  }

  &__item-arrow {
    display: flex;
    align-items: center;
    height: 168px;

    @include for-desktop-down {
      display: none;
    }
  }

  &__button {
    margin-left: auto;
    margin-right: auto;
  }

  &__decor {
    position: absolute;
    width: 460px;
    top: calc(100% - 285px);
    left: 50%;
    transform: translateX(calc(-50% - 105px));

    @include for-desktop-up {
      top: calc(100% - 450px);
      left: auto;
      right: -205px;
      transform: none;
      width: auto;
    }

    @media (min-width: 992px) and (max-width: 1690px) {
      top: calc(100% - 300px);
      left: auto;
      right: -48px;
      transform: none;
      width: 500px;
    }

    @media (min-width: 992px) and (max-width: 1380px) {
      right: -15px;
    }
  }
}
