@import "/src/styles/partials/mixins";
@import "/src/styles/partials/settings";

.faq {
  background: #FFF;
  color: $text;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    margin: 32px auto 0;

    @include for-desktop-up {
      margin-top: 80px;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;
    position: relative;
    outline: none;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #BEBEBE;
    }
  }

  &__question {
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;
    justify-content: space-between;

    @include for-desktop-up {
      cursor: pointer;
    }

    h3 {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;

      @include for-desktop-up {
        line-height: 26px;
      }
    }
  }

  &__answer {
    margin-top: 28px;

    @include for-desktop-up {
      margin-top: 39px;
    }
  }

  &__answer-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
}
